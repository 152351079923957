import React from "react";

export default function SubmittedForm() {


  return(

    <div id="formWrapper">
      <form>
        <h2>Thank you for your message. I'll be in touch ASAP</h2>
        <hr />
      </form>
    </div>

  );
};